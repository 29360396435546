import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IImage } from '../../../models/cmsModels';
import '../../../helpers/fontawesome';
import './image-gallery.scss';

interface IProps {
    images: IImage[];
    defaultIndex?: number;
    onClose: Function;
}

const ImageGallery = (props: IProps) => {
    const [index, setIndex] = useState(props.defaultIndex || 0);
    const [isZoomed, setZoomed] = useState(false);
    const [imageStyle, setImageStyle] = useState({});

    useEffect(() => {
        function keyup(event: any) {
            console.log(event)
          if (event.keyCode === 27) {
            props.onClose();
          }
        }
  
        document.addEventListener('keyup', keyup);  
  
        return () => {
          document.removeEventListener("keyup", keyup);
        }
    }, []);
    
    const next = () => {
        const i = index > 0 ? index - 1 : index;
        setIndex(i);
    };

    const previous = () => {
        const i = index < props.images.length - 1 ? index + 1 : index;
        setIndex(i);
    };

    const leftArrowClass = !index ? ' disabled' : '';

    const rightArrowClass = (index === props.images.length - 1) ? ' disabled' : '';

    const zoom = () => {
        const zoom = !isZoomed;
        setZoomed(zoom);

        console.log('zoom', zoom)
        setImageStyle(zoom ? {
            maxWidth: '100%',
            height: '100%',
            cursor: 'zoom-out',
            paddingTop: '8px',
            paddingBottom: '8px'
        } : {});
    };

    const summaryText = (index + 1) + '/' + props.images.length;

    const image = props.images[index];
    const imageSrc = image?.formats?.small?.childImageSharp?.fluid?.src || image?.url;

    return (
        <div className="gallery-modal">
            <div className="modal-cross">
                <FontAwesomeIcon icon="times" className="fa-lg" onClick={() => props.onClose()} />
            </div>
            <div className="modal-main">
                <div className={"caret" + leftArrowClass} onClick={() => next()} title="Previous">
                    <FontAwesomeIcon icon="caret-left" className="fa-3x" />
                </div>
                <img className="modal-content" src={imageSrc} onClick={zoom} style={imageStyle} />
                <div className={"caret right" + rightArrowClass} onClick={() => previous()} title="Next">
                    <FontAwesomeIcon icon="caret-right" className="fa-3x" />
                </div>
            </div>
            <div className="modal-summary">{summaryText}</div>
        </div>
    );
};

export default ImageGallery;